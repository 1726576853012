import localforage from "localforage";
import { request2 } from "utils/request";

export interface TrackingPoint {
    userName?: string;
    nickName?: string;
    relType?: string;
    relId?: string;
    event?: string;
    title?: string;
    extra?: string;
    path?: string;
    _id?: string;
}

class Tracker {
    private cacheKey = "_tracker_cache_"
    constructor() {
        this.autoSend()
    }

    public async pushEvent(param: TrackingPoint) {
        const extraStr: string = param.extra ? typeof param.extra == 'string' ? param.extra : JSON.stringify(param.extra) : "";
        const _param = {
            ...param,
            path: window.location.href,
            extra: extraStr,
            ts: Date.now(),
            userAgent: navigator.userAgent,
            _id: `${Math.random()}_${Date.now()}`
        }
        const list: TrackingPoint[] = await localforage.getItem(this.cacheKey) ?? []
        list.push(_param)
        await localforage.setItem(this.cacheKey, list)
        if(list.length > 10) this.sendEvent()
    }

    public async sendEvent() {
        const list: TrackingPoint[] = await localforage.getItem(this.cacheKey) ?? []
        if(list.length) {
            await request2({ url: `/tracking`, method: 'POST', data: { datas: list} })
            await localforage.removeItem(this.cacheKey)
        }
    }

    private autoSend() {
        setTimeout(async () => {
            try {
                await this.sendEvent()
            } catch (error) {
                console.error(error)
            }
            this.autoSend()
        }, 1000 * 20);
    }
}

export const tracker = new Tracker()
